function supportsESModules() {
  try {
    // eslint-disable-next-line no-new,no-new-func
    new Function('import("")');
    return true;
  } catch (e) {
    return false;
  }
}

function supportsDialog() {
  return typeof HTMLDialogElement === 'function';
}

function supportsDetails() {
  return typeof HTMLDetailsElement === 'function';
}

function supportsViewTransition() {
  return 'startViewTransition' in document;
}

function supportsCustomElements() {
  return 'customElements' in window;
}

function supportsCSSGrid() {
  const div = document.createElement('div');

  div.style.display = 'grid';
  return div.style.display === 'grid';
}

function supportsCSSVariables() {
  const div = document.createElement('div');

  div.style.setProperty('--test', '1rem');
  return div.style.getPropertyValue('--test') === '1rem';
}

function supportsMediaQueryRangeSyntax() {
  try {
    return window.matchMedia('(width >= 0px)').matches;
  } catch (e) {
    return false;
  }
}

function supportsAtRule(textContent) {
  try {
    const style = document.createElement('style');

    style.textContent = textContent;
    document.head.appendChild(style);
    const isSupported = style.sheet && style.sheet.cssRules.length > 0;

    document.head.removeChild(style);
    return isSupported;
  } catch (e) {
    return false;
  }
}

function supportsSelector(selector) {
  try {
    document.querySelector(selector);
    return true;
  } catch (e) {
    return false;
  }
}

function supportsAtContainer() {
  return supportsAtRule('@container (min-width: 200px) { h1 { color: red; } }');
}

function supportsAtLayer() {
  return supportsAtRule('@layer { h1 { color: red; } }');
}

function supportsAtScope() {
  return supportsAtRule('@scope { h1 { color: red; } }');
}

function supportsIsSelector() {
  return supportsSelector(':is(div)');
}

function supportsWhereSelector() {
  return supportsSelector(':where(div)');
}

function supportsNestedStyles() {
  return supportsSelector('&');
}

function supportsPopover() {
  const button = document.createElement('button');

  return typeof button.showPopover === 'function';
}

const imageSrc = JSON.stringify({
  token: window.Site.jitsuPixelFeatureDetection,
  event_type: 'feature_detection',
  doc_path: window.location.pathname,
  doc_host: window.location.hostname,
  feature_detection_names: [
    'es_modules',
    'dialog_element',
    'details_element',
    'view_transition_api',
    'custom_elements_api',
    'css_grid',
    'custom_properties',
    'media_queries_range_syntax',
    '@container-at-rule',
    '@layer-at-rule',
    '@scope-at-rule',
    ':is-directive',
    ':where-directive',
    'nested_styles_syntax',
    'popover_api'
  ],
  feature_detection_is_available: [
    supportsESModules(),
    supportsDialog(),
    supportsDetails(),
    supportsViewTransition(),
    supportsCustomElements(),
    supportsCSSGrid(),
    supportsCSSVariables(),
    supportsMediaQueryRangeSyntax(),
    supportsAtContainer(),
    supportsAtLayer(),
    supportsAtScope(),
    supportsIsSelector(),
    supportsWhereSelector(),
    supportsNestedStyles(),
    supportsPopover()
  ]
});

const PIXEL_IMAGE_SRC = window.btoa(imageSrc);

const imageElement = document.createElement('img');

imageElement.setAttribute('src', `${window.Site.jitsuParams.host}/api/v1/p.gif?data=${PIXEL_IMAGE_SRC}`);
imageElement.setAttribute('width', '1');
imageElement.setAttribute('height', '1');
imageElement.setAttribute('style', 'position: absolute; left: -999px; top: -999px; visibility: hidden; z-index: -9999');
imageElement.setAttribute('alt', 'jitsu gif');

requestAnimationFrame(() => {
  document.body.appendChild(imageElement);
});
